<template>
    <div class="callout">
        <div style="display: flex; gap: 25px; flex-wrap: wrap;">
            <input style="height: 42px;width:250px" type="text" class="form-control" placeholder="Expediente" v-model="filtroExpediente">    
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="companiaseleccionada" :options="companias" filter
                optionLabel="nombre" style="width:250px" placeholder="Compañías">
            </MultiSelect>        
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="usuarioseleccionado" :options="usuarios" filter
                optionLabel="nombre" style="width:250px" placeholder="Usuarios">
            </MultiSelect>
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="operarioseleccionado" :options="operarios" filter
                optionLabel="nombre" style="width:250px" placeholder="Operarios">
            </MultiSelect>
            <MultiSelect optionValue="id_estado" scrollHeight="400px" showClear v-model="estadoseleccionado" :options="estados" filter
                optionLabel="nombre" style="width:250px" placeholder="Estados pendientes">
            </MultiSelect>
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="especialidadseleccionada" :options="especialidades" filter
                optionLabel="nombre" style="width:250px" placeholder="Especialidades">
            </MultiSelect>
            <button class="btn btn-primary" @click="obtenerExpedientes(mievento)">Buscar</button>
        </div>
    </div>
    <tabla-datos :trabajos="true" :botones="true" :columnas="columnas" :datos="expedientes" :filtros="false" 
     :total="total" @quieroDatos="obtenerExpedientes($event); mievento=$event" @page="onPage($event)" :page="pageselec" :num="numdatos" :servicios="true" v-if="mostrar">
    </tabla-datos>
    <Dialog modal :header="cabecera" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        v-model:visible="visible">
        <p v-html=" mensaje">
        </p>
    </Dialog>
</template>
<script>

import MultiSelect from 'primevue/multiselect';
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue';
import Dialog from 'primevue/dialog'; export default {
    components:{     
        MultiSelect,
        "tabla-datos": TablaDatos,
        Dialog,
    },
    data() {
        return {
            especialidades:[],
            especialidadseleccionada:'',
            mievent:[],
            mostrar:false,
            usuarioseleccionado:'',
            companiaseleccionada:'',
            filtroExpediente: '',
            estados:[],
            estadoseleccionado:'',
                pageselec:1,
                numDatos:15,
                companias:[],
            usuarios:[],
            visible:false,
            operarios:[],
            operarioseleccionado:'',
               total: '',
               expedientes: [],
               parametros: '',
               cabecera:'',
               mensaje:'',
               columnas: [
                   {
                       header: 'Tipo',
                       field: 'class',
                   },
                
                   {
                       header: 'Expediente',
                       field: 'codigo_servicio',
                       sortable: false,
                       acciones: [
                           {
                               link: true,
                               tipo: 'default',
                               nombreId: 'id_servicio',
                               nombreIddos: 'codigo_servicio',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id, codigo) => {
                                   console.log('Editar expediente ' + id);
                                   localStorage.setItem('nombreservicio' + id, codigo);
                                   localStorage.setItem('pestanyactivaservicio' + id, 'notas');

                                   this.$router.push({
                                       name: 'Servicio',
                                       params: {
                                           id: id,
                                           nombreservicio: codigo,
                                       }
                                   });
                               }
                           },
                       ]
                   },
                   {
                       header: 'Compañia',
                       field: 'nombre_compania',
                   },
                   {
                       header: 'Fecha/Hora',
                       field: 'fecha_hora',
                       sortable: true,
                   },
                   {
                       header: 'Operario',
                       field: 'nombre_operario',
                   },
                   {
                       header: 'Estado Pendiente',
                       field: 'nombre_especialidad_pendiente',
                   },
                   {
                       header: 'Especialidad',
                       field: 'nombre_especialidad',
                   },

                   {
                       header: 'Tramitador',
                       field: 'nombre_tramitador',
                       sortable: false,
                   },
                   {
                       data: null,
                       header: "Notas",
                       acciones: [
                           {
                               nombre: 'Nota',
                               class: 'nota',
                               tipo: 'default',
                               nombreId: 'texto_nota',
                               nombreIddos: 'codigo_servicio',

                               icono: 'far fa-clipboard',
                               // Debe ser arrow function para que this sea este componente
                               accion: (texto_nota,codigo_servicio) => {
                                   this.mensaje = texto_nota;
                                   this.cabecera = codigo_servicio;
                                   this.visible = true;
                                                                  }
                           },
                       ]
                   },
                   
               ]
           }
       },
       methods: {
        async cargarEspecialidades() {
            const api = new PwgsApi();
            const datos = await api.get('especialidades');
            this.especialidades = datos.datos;
        },
        async cargarEstados() {
            const api = new PwgsApi();
            const datos = await api.get('tipos-estado-pendiente');
            this.estados = datos.datos;
        },
        async cargarCompanias() {
            const api = new PwgsApi();
            const datos = await api.get('companias/simple?sortField=nombre&sortOrder=1');
            this.companias = datos.datos;
        },
        async cargarOperarios() {
            const api = new PwgsApi();
            const datos = await api.get('operarios/simple');
            this.operarios = datos.datos;
        },
        async cargarUsuarios() {
            const api = new PwgsApi();
            const datos = await api.get('usuarios/gestion,simple');
            this.usuarios = datos.datos;
        },
            onPage(evnt){
                localStorage.setItem("pageselectnr", evnt.page);
                localStorage.setItem("numdatstnr", evnt.rows);
                console.log("evvvv", evnt);
            },
           async obtenerExpedientes(event) {
                if(this.especialidadseleccionada){
                    event.ids_especialidades = this.especialidadseleccionada;
                }
                if(this.estadoseleccionado){
                    event.ids_esp_pend = this.estadoseleccionado;
                }
                if(this.operarioseleccionado){
                    event.ids_operarios = this.operarioseleccionado;
                }
                if (this.companiaseleccionada) {
                event.ids_companias = this.companiaseleccionada;
                }
                if (this.usuarioseleccionado) {
                event.ids_usuarios = this.usuarioseleccionado;
                }
                if(this.filtroExpediente !=''){
                    event.buscador = this.filtroExpediente;
                }else{
                    delete event.buscador;
                }
               const api = new PwgsApi;
               const aux = await api.post('avisos/trabajos-no-revisados',event);
               this.expedientes = aux.datos;
               this.total = aux.n_total_registros;
               this.$emit('evento_total_trabajos_no_revisados', this.total); //Es llamado por ./Escritorio.vue
           },
           toggle(event) {
               this.$refs.op.toggle(event);
           }
       },
       activated() {
           //this.obtenerExpedientes();
       },
           mounted() {
            this.cargarCompanias();
            this.cargarUsuarios();
            this.cargarOperarios();
            this.cargarEspecialidades();
            this.cargarEstados();
            this.pageselec = parseInt(localStorage.getItem('pageselectnr'));
            this.numdatos = parseInt(localStorage.getItem('numdatstnr'));
            console.log('pageselec',this.pageselec);
            this.mostrar = true;
           //this.obtenerExpedientes();
       },
        watch:{
            estadoseleccionado(){
                this.pageselec = 1;
                this.mievento["first"]=0;
                if (!this.estadoseleccionado) {
                    delete this.mievento["ids_esp_pend"];
                }
            },
            especialidadseleccionada(){
                this.pageselec = 1;
                this.mievento["first"]=0;
                if (!this.especialidadseleccionada) {
                    delete this.mievento["ids_especialidades"];
                }
            },
            operarioseleccionado(){
                this.pageselec = 1;
                this.mievento["first"]=0;
                if (!this.operarioseleccionado) {
                    delete this.mievento["ids_operarios"];
                }
            },
            usuarioseleccionado(){
                this.pageselec = 1;
                this.mievento["first"]=0;
                if (!this.usuarioseleccionado) {
                    delete this.mievento["ids_usuarios"];
                }
            },
            companiaseleccionada(){
                this.pageselec = 1;
                this.mievento["first"]=0;
                if (!this.companiaseleccionada) {
                    delete this.mievento["ids_companias"];
                }
            },
            filtroExpediente(){
                this.pageselec = 1;
                this.mievento["first"]=0;
            }
        }
   }
   </script>
   <style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   </style>